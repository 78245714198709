<template>
  <div>
    <div class="d_flex align-center">
      <tp-input-price
        class="text-body-1 pt-0 mt-0"
        v-model="from"
        hide-details
        single-line
        suffix="đ"
        placeholder="Giá trị"
        :min="0"
        @input="updateFromValue($event)"
      ></tp-input-price>
      <div class="px-2">&minus;</div>
      <tp-input-price
        class="text-body-1 pt-0 mt-0"
        :error="parseInt(from) > parseInt(to)"
        v-model="to"
        hide-details
        single-line
        placeholder="Giá trị"
        :min="0"
        suffix="đ"
        @input="updateToValue($event)"
      ></tp-input-price>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      from: 0,
      to: 0
    };
  },
  created() {
    if (this.value) {
      this.from = this.value.from;
      this.to = this.value.to;
    } else {
      this.from = 0;
      this.to = 0;
    }
  },
  methods: {
    formatToCurrency(amount) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      const str = formatter.format(amount);

      return str.slice(0, str.length - 2);
    },

    updateFromValue(val) {
      this.$emit("change", {
        from: val,
        to: this.to
      });
    },

    updateToValue(val) {
      this.$emit("change", {
        from: this.from,
        to: val
      });
    }
  }
};
</script>
